import React from 'react';
import { PageProps } from 'gatsby';
import logo from '../assets/moesc-logo.png';

import './index.css';

const Home: React.FC<PageProps> = () => (
  <main className="mainWrapper">
    <div className="logoContainer">
      <img className="logo" src={logo} alt="moesc logo" />
    </div>
  </main>
);

export default Home;
